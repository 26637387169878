import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [];

export const dictionary = {
		"/(web)": [47,[12]],
		"/3d": [59],
		"/(web)/(chatbots)/about": [49,[12,14]],
		"/(admin)/admin/dashboard": [16,[2]],
		"/(app)/app": [17,[3,4]],
		"/(app)/app/date": [19,[3,4,5]],
		"/(app)/app/date/[dateCompanionId]": [20,[3,4,5]],
		"/(app)/app/gallery": [21,[3,4,6]],
		"/(app)/app/gallery/[imageId]": [22,[3,4,6]],
		"/(app)/app/onboarding-deprecated": [24,[8]],
		"/(app)/app/onboarding-deprecated/interests": [25,[8]],
		"/(app)/app/onboarding": [23,[7]],
		"/(app)/app/settings/(sub-settings)/account": [26,[3,4,9]],
		"/(app)/app/settings/(sub-settings)/companions": [27,[3,4,9]],
		"/(app)/app/settings/(sub-settings)/companions/[companionId]": [28,[3,4,9,10]],
		"/(app)/app/settings/(sub-settings)/companions/[companionId]/appearance": [29,[3,4,9,10]],
		"/(app)/app/settings/(sub-settings)/companions/[companionId]/personality": [30,[3,4,9,10]],
		"/(app)/app/settings/(sub-settings)/companions/[companionId]/voice": [31,[3,4,9,10]],
		"/(app)/app/settings/(sub-settings)/profile": [32,[3,4,9]],
		"/(app)/app/settings/(sub-settings)/subscription": [~33,[3,4,9]],
		"/(app)/app/success": [34,[3]],
		"/(app)/app/[catch_redirect]": [18,[3,4]],
		"/(web)/articles": [55,[12]],
		"/(web)/articles/[slug]": [56,[12]],
		"/(app)/auth": [35,[3]],
		"/(app)/auth/forgot-password": [37,[3]],
		"/(app)/auth/forgot-password/check-mails": [38,[3]],
		"/(app)/auth/forgot-password/set-new": [39,[3]],
		"/(app)/auth/login": [40,[3]],
		"/(app)/auth/logout": [41,[3]],
		"/(app)/auth/redirect-to-app": [42,[3]],
		"/(app)/auth/register": [43,[3]],
		"/(app)/auth/register/verify-email": [44,[3]],
		"/(app)/auth/[catch_redirect]": [36,[3]],
		"/(web)/blog/[slug]": [57,[12]],
		"/(web)/(chats)/chat/[name]": [~51,[12,15]],
		"/(web)/(chatbots)/compare/[slug]": [50,[12,14]],
		"/(web)/install": [58,[12]],
		"/(web)/(privacy-cookies)/privacy": [52,[12]],
		"/(web)/(alt-landings)/roleplay-ai": [48,[12,13]],
		"/(web)/(privacy-cookies)/terms": [53,[12]],
		"/(tools)/tools/fake-text-message-generator": [45,[11]],
		"/(tools)/tools/uncensored-ai-image-generator": [46,[11]],
		"/(web)/[slug]": [54,[12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';